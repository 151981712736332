import {CB_BRANDING} from "../../constants/branding"
import canalblogTheme from "./canalblog"
import overblogTheme from "./overblog"

export const renderTheme = (branding) => {
  switch (branding) {
    case CB_BRANDING:
      return canalblogTheme
    default:
      return overblogTheme
  }
}
