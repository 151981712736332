import React from "react"
import {FormattedMessage, useIntl} from "react-intl"
import styled from "styled-components"
import Image from "./image"
import Slider from "./slider"
import View from "./view"
import {UserAlt} from "../icons/UserAlt"
import {isFR} from "../utils/is-fr"
import {useTheme} from "../hooks/use-theme"
import {OB_BRANDING} from "../constants/branding"

const {PUBLIC_URL, APP_BRAND, PORTAL_URL, REACT_APP_CONNECT_URL} = process.env

const Circle = styled(View)`
  border-radius: 50%;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: 1px solid currentColor;
  justify-content: center;
  align-items: center;
`

const Header = ({children}) => {
  const {locale} = useIntl()
  const {theme} = useTheme()

  return (
    <View
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems={{xs: "center", m: "stretch"}}
      mb={3}
    >
      <View
        clipPath="polygon(0 0, 100% 0, 100% 80%, 0 90%)"
        position="absolute"
        width="100%"
        height="100%"
        zIndex={-1}
      >
        <Image
          src={`${PUBLIC_URL}/assets/images/${APP_BRAND}/background.jpg`}
          width="100%"
          height="100%"
          objectFit="cover"
        />
        <View
          position="absolute"
          top={0}
          width="100%"
          height="100%"
          background="rgba(16, 16, 16, 0.6)"
        />
      </View>
      <View
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        maxWidth={{xs: 500, m: 1200, l: 1700}}
        mx={{xs: 0, m: 4}}
        mt={{xs: 1, s: 2, m: 3}}
        palette="white"
      >
        <View display="flex" width="100%" flexWrap="wrap" px={{xs: 1, m: 0}}>
          <View
            display="flex"
            flexDirection={{xs: "column", m: "row"}}
            justifyContent={{xs: "center", m: "start"}}
            alignItems={isFR(locale) ? "center" : {xs: "start", m: "center"}}
            flex={
              isFR(locale)
                ? {xs: "1 1 100%", m: "1 1 50%"}
                : {xs: "1 1 50%", m: "1 1 50%"}
            }
            order={isFR(locale) ? {xs: 3, m: 1} : {xs: 1, m: 1}}
          >
            <Image
              src={`${PUBLIC_URL}/assets/images/${APP_BRAND}/logo.png`}
              width={160}
              height={65}
              mt={isFR(locale) && {xs: 1, m: 0}}
            />
            {!isFR(locale) && (
              <View display="flex" ml={{xs: 0, m: 4}} mb={{xs: 2, m: 0}}>
                <View
                  as="a"
                  textDecoration="none"
                  href={`/blogs`}
                  display="flex"
                  mr={2}
                >
                  <FormattedMessage id="header.nav.blogs" />
                </View>
                <View
                  as="a"
                  textDecoration="none"
                  href={`/premium`}
                  display="flex"
                >
                  <FormattedMessage id="header.nav.premium" />
                </View>
              </View>
            )}
          </View>
          <View
            display="flex"
            flex={{xs: "none", m: "1 1 50%"}}
            justifyContent="flex-end"
            alignItems={isFR(locale) ? "center" : {xs: "start", m: "center"}}
            mt={isFR(locale) ? 0 : {xs: 2, m: 0}}
            order={2}
          >
            <>
              <Circle
                as="a"
                href={REACT_APP_CONNECT_URL}
                display={{xs: "flex", s: "none"}}
                size={25}
              >
                <UserAlt size={11} />
              </Circle>
              <View
                as="a"
                textDecoration="none"
                href={REACT_APP_CONNECT_URL}
                display={{xs: "none", s: "flex"}}
              >
                <FormattedMessage id="header.nav.login" />
              </View>
            </>
          </View>
          {isFR(locale) && (
            <View
              display="flex"
              pt={{xs: 0, m: 2}}
              flex={{xs: "1 1 0%", m: "1 1 100%"}}
              order={{xs: 1, m: 3}}
              alignSelf="center"
            >
              <View as="a" textDecoration="none" href={PORTAL_URL} fontSize={3}>
                <FormattedMessage id="header.nav.portal" />
              </View>
              <View px={1}>{">"}</View>
              <FormattedMessage id="header.nav.create_a_blog">
                {(text) => (
                  <View as="h1" fontSize={3} m={0} fontWeight="normal">
                    {text}
                  </View>
                )}
              </FormattedMessage>
            </View>
          )}
        </View>
        <View display="flex" mt={isFR(locale) ? 3 : 0}>
          <View
            flex="1"
            flexDirection="column"
            column
            display={{xs: "none", m: "flex"}}
            justifyContent="center"
          >
            <View fontSize={{xs: null, m: 6, l: 7, xl: 8}}>
              <FormattedMessage id="header.nav.publish_ideas" />
            </View>
            <View
              display="flex"
              fontSize={{xs: null, m: 6, l: 7, xl: 8}}
              mb={2}
            >
              <FormattedMessage id="header.nav.create_blog">
                {(text) => (
                  <View as="span" whiteSpace="nowrap">
                    {text}
                  </View>
                )}
              </FormattedMessage>
              <Slider />
            </View>
            {APP_BRAND === OB_BRANDING && (
              <View fontSize={{xs: null, m: 2, l: 4, xl: 6}}>
                <FormattedMessage id="header.nav.first_european_platform" />
              </View>
            )}
          </View>
          <View maxWidth={500}>
            {!isFR(locale) && (
              <View
                as="h1"
                fontSize="28px"
                display="flex"
                width="100%"
                fontWeight="normal"
                justifyContent="center"
                m={0}
                mb={2}
              >
                <FormattedMessage id="header.nav.create_a_blog" />
              </View>
            )}
            <View
              borderRadius={6}
              overflow="hidden"
              boxShadow={`3px 3px 10px ${theme.palette.shadow}`}
            >
              {children}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}
export default Header
